import {PropsWithChildren, useEffect, useState} from "react";
import {ActivityLookupViewCreator} from "../view-creator/ActivityLookupViewCreator";
import {Lookup} from "../views/Lookup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Help} from "@mui/icons-material";
import {GoalView} from "../views/GoalView";
import logger from "../utils/logger";


export interface CreateGoalFormProps extends PropsWithChildren {
  formState: GoalView;
  onChange: (fieldName: string, value: any) => void;
  validationErrors: Record<string, string | null>;
}

export const CreateGoalForm: React.FC<CreateGoalFormProps> = ({
                                                                formState,
                                                                onChange,
                                                                validationErrors,
                                                              }) => {
  const [activities, setActivities] = useState<Lookup[]>([]);

  useEffect(() => {
    const fetchActivities = async () => {
      const view = await new ActivityLookupViewCreator().createView();
      setActivities(view.activities);
    };
    fetchActivities()
            .catch((err) => logger.error(err));
  }, []);

  return (
          <Box display="flex" flexDirection="column" sx={{gap: 2}}>
            <FormControl error={!!validationErrors.activityId}>
              <InputLabel id="activity">Activity</InputLabel>
              <Select label="Activity"
                      name="activity"
                      value={formState.activityId}
                      onChange={(e) => onChange("activityId", e.target.value)}
              >
                <MenuItem value={-1}>Select an Activity</MenuItem>
                {activities.map((activity) => (
                        <MenuItem key={activity.id} value={activity.id}>
                          {activity.name}
                        </MenuItem>
                ))}
              </Select>
              {validationErrors.activityId && <FormHelperText>{validationErrors.activityId}</FormHelperText>}
            </FormControl>

            <Grid container display={"flex"} flexDirection={"row"} gap={1}>
              <FormControl sx={{width: 75}}>
                <TextField label="Frequency"
                           type="number"
                           name="frequency"
                           value={formState.frequency}
                           onChange={(e) => onChange("frequency", Number(e.target.value))}
                           error={!!validationErrors.frequency}
                           helperText={validationErrors.frequency}
                />
              </FormControl>
              <FormControl sx={{width: '79%'}}>
                <InputLabel id="timeframe">Timeframe</InputLabel>
                <Select label="Timeframe"
                        name="timeframe"
                        defaultValue={1}
                        size={"medium"}
                        variant={"outlined"}
                        labelId={"timeframe"}
                        value={formState.timeframe}
                        onChange={(e) => onChange("timeframe", e.target.value)}
                >
                  <MenuItem selected key={1} value={1}>{"Times Per Week"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container display={"flex"} flexDirection={"row"} gap={1}>
              <FormControl sx={{width: 75}}>
                <TextField name={"duration"}
                           type="number"
                           value={formState.duration}
                           onChange={(e) => onChange("duration", Number(e.target.value))}
                           error={!!validationErrors.duration}
                           helperText={validationErrors.duration}
                           onFocus={(e) => {
                             e.target.select()
                           }}
                ></TextField>
              </FormControl>
              <FormControl sx={{width: "79%"}}>
                <InputLabel id="duration">Duration</InputLabel>
                <Select defaultValue={2}
                        name={"durationTimeframe"}
                        value={formState.durationTimeframe}
                        label={"Duration"}
                        size={"medium"}
                        variant={"outlined"}
                        labelId={"duration"}
                        onChange={(e) => onChange("durationTimeframe", e.target.value)}>
                  <MenuItem selected key={2} value={2}>{"Minutes"}</MenuItem>
                </Select>
                {validationErrors.durationTimeframe &&
                        <FormHelperText>{validationErrors.durationTimeframe}</FormHelperText>}
              </FormControl>
              <Tooltip enterTouchDelay={300} arrow
                       title="Select a duration. This is usually Y minutes per time.">
                <IconButton>
                  <Help/>
                </IconButton>
              </Tooltip>
            </Grid>
            <DatePicker
                    label="Start Date"
                    value={formState.startDate}
                    onChange={(date) => onChange("startDate", date)}
            />
            {validationErrors.startDate && <FormHelperText>{validationErrors.startDate}</FormHelperText>}

            <DatePicker
                    label="End Date"
                    value={formState.endDate}
                    onChange={(date) => onChange("endDate", date)}
            />
            {validationErrors.endDate && <FormHelperText>{validationErrors.endDate}</FormHelperText>}

            <TextField
                    label="Alternative Goal"
                    name="alternate"
                    value={formState.alternate}
                    onChange={(e) => onChange("alternate", e.target.value)}
                    error={!!validationErrors.alternate}
                    helperText={validationErrors.alternate}
            />

          </Box>
  );
};
