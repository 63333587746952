import {DebugPanel} from "../DebugPanel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import {CreateGoalForm} from "../CreateGoalForm";
import {AddCircleOutline, AssignmentTurnedIn, CancelOutlined} from "@mui/icons-material";
import {palette} from "../../palette";
import React, {useEffect, useState} from "react";
import {GoalView} from "../../views/GoalView";
import {RawGoal} from "../../views/RawGoal";

export interface UpdateGoalsDialogProps {
  showNewGoalDialog: boolean;
  onModalClose: (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => void;
  view: GoalView;
  validationErrors: Record<string, string | null>;
  handleChange: (fieldName: string, value: any) => void;
  onAddGoal: (e: any) => void;
  onRemoveGoal: (e: any, idx: number) => void;
  addRawGoal: () => void;
  rawGoals: RawGoal[];
}

export const UpdateGoalsDialog: React.FC<UpdateGoalsDialogProps> = ({
                                                                      showNewGoalDialog,
                                                                      onModalClose,
                                                                      view,
                                                                      validationErrors,
                                                                      handleChange,
                                                                      onAddGoal,
                                                                      onRemoveGoal,
                                                                      addRawGoal,
                                                                      rawGoals,
                                                                    }) => {
  const [newGoals, setNewGoals] = useState<string[]>([]);
  const handleSave = (e: any) => {
    setNewGoals([...newGoals]);
    onModalClose(e, 'SAVE', rawGoals)
  }
  const handleRemoveGoal = (e: any, idx: number) => {
    newGoals.splice(idx, 1);
    setNewGoals([...newGoals]);
    onRemoveGoal(e, idx)
  }
  const handleAddGoal = (e: any) => {
    onAddGoal(e);
    newGoals.push(view.name)
    setNewGoals([...newGoals])
  }

  const [addGoalDisabled, setAddGoalDisabled] = useState<boolean>(true)
  const [saveGoalDisabled, setSaveGoalDisabled] = useState<boolean>(true)
  useEffect(() => {
    const errorCount = Object.entries(validationErrors)
                             .reduce((value, item) => {
                               if (item[1]) {
                                 return value += 1;
                               }
                               return value
                             }, 0)

    setAddGoalDisabled(errorCount > 0);
    setSaveGoalDisabled(errorCount > 0 || rawGoals.length < 1);
  }, [
    validationErrors,
    rawGoals]);

  return (<Dialog open={showNewGoalDialog}
                  onClose={(e: any) => onModalClose(e, 'CANCEL', [])}
                  slotProps={{backdrop: {style: {backgroundColor: 'background.paper'}}}}>


            <DebugPanel displayItem={{view: view, errors: validationErrors}}></DebugPanel>
            <DialogTitle>Add new Goal</DialogTitle>
            <Divider></Divider>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 4, overflow: 'scroll'}}>
              <CreateGoalForm
                      formState={view}
                      onChange={handleChange}
                      validationErrors={validationErrors}/>
              <IconButton sx={{alignSelf: "flex-start"}} color={"primary"} size={"small"}
                          onClick={handleAddGoal}
                          disabled={addGoalDisabled}><AddCircleOutline/> Add Goal</IconButton>
              {newGoals.length > 0 && <Box sx={{border: 1, borderRadius: 5, borderColor: palette.background.border}}>
                <List>
                  {newGoals.map((goal, idx) => {
                    return (<ListItem>
                      <ListItemIcon><AssignmentTurnedIn color={"primary"}/></ListItemIcon>
                      <ListItemText>{goal}</ListItemText>
                      <ListItemButton onClick={(e) => handleRemoveGoal(e, idx)}>
                        <CancelOutlined color={"primary"}/>
                      </ListItemButton>
                    </ListItem>)
                  })}
                </List>
              </Box>}
            </DialogContent>
            <DialogActions>
              <Button disableElevation
                      color={"secondary"}
                      size={"large"}
                      variant={"contained"}
                      onClick={(e: any) => onModalClose(e, 'CANCEL', [])}>Cancel</Button>
              <Button variant={"contained"}
                      disabled={saveGoalDisabled}
                      size={"large"}
                      onClick={handleSave}>Save</Button>
            </DialogActions>
          </Dialog>
  )
}
