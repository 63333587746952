import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  List,
  ListItem,
  Skeleton,
  Snackbar,
  ToggleButton,
  Typography
} from "@mui/material";
import React, {Fragment} from "react";
import {ExerciseHistory} from "../modals/ExerciseHistory";
import {ActivityHistoryView} from "../../views/ActivityHistoryView";
import {GoalSummaryView} from "../../views/DashboardView";
import {RecordExercise} from "../modals/RecordExercise";
import {Circle, CircleOutlined} from "@mui/icons-material";
import {DebugPanel} from "../DebugPanel";
import {useUiState} from "../../utils/UiStateHook";
import {palette} from "../../palette";
import {RawGoal} from "../../views/RawGoal";
import {GoalCompletionStatus, useGoalsTrackerCardState} from "./useGoalTrackerState";
import {ConfirmGoalCompletionDialog} from "./ConfirmGoalCompletionDialog";
import {UpdateGoalsDialog} from "./UpdateGoalsDialog";

interface GoalTrackerCardProps {
  cohortUserId: number,
  goals: { goals: GoalSummaryView[], progress: GoalCompletionStatus },
  isLoading: boolean
  activityHistoryView: ActivityHistoryView,
  enabled?: boolean,
  newGoalEnabled?: boolean,
  disabledMessage: string,
  refreshActivities: () => void,
  jailCards: { id: number, cohortUserId: number, used: boolean, usedOn: Date }[],
  onUpdateGoal: (e: any) => void,
  week1: boolean,
  onModalClose: (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => void;
  showNewGoalDialog: boolean
}
export const GoalTrackerCard: React.FC<GoalTrackerCardProps> = ({
                                                                  enabled = false,
                                                                  newGoalEnabled = false,
                                                                  week1 = false,
                                                                  disabledMessage,
                                                                  jailCards,
                                                                  refreshActivities,
                                                                  isLoading,
                                                                  activityHistoryView,
                                                                  goals,
                                                                  cohortUserId,
                                                                  onUpdateGoal,
                                                                  showNewGoalDialog,
                                                                  onModalClose,
                                                                }) => {
  const uiState = useUiState(enabled);
  const {
    handleActivityChange,
    activityView,
    activityTrackingDialogOpen,
    setActivityTrackingDialogOpen,
    addActivity,
    handleToggle,
    historyDialogOpen,
    setHistoryDialogOpen,
    jailCardOpen,
    setJailCardOpen,
    confirmGoalDialogOpen,
    alertOpen,
    setAlertOpen,
    goalCompletionStatus,
    selectedGoal,
    setConfirmGoalDialogOpen,
    setGoalCompletionStatus,
    trackActivity,
    validationErrors,
    view,
    snackAlertAction,
    onAddGoal,
    onRemoveGoal,
    addRawGoal,
    rawGoals,
    handleChange
  } = useGoalsTrackerCardState(goals, cohortUserId, refreshActivities);


  return <Fragment>
    <Card variant={"elevation"} sx={{
      height: 300,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'scroll',
      border: '1px solid',
      borderColor: uiState.borderColor,
      borderRadius: 4
    }}>
      <CardContent>
        <Typography variant={"h5"} component={"div"} color={uiState.color}>My Exercise Goal</Typography>
        {!enabled &&
                <Typography color={"primary"}
                            sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                            variant="h5" component="p" gutterBottom>{disabledMessage}</Typography>}

        {isLoading &&
                <ul>
                  <li>
                    <Typography color={uiState.color} variant={"h6"} sx={{fontWeight: 300}}>
                      <Skeleton width={'80%'}></Skeleton>
                    </Typography>
                  </li>
                </ul>
        }
        {!isLoading &&
                <Grid container display={"flex"} flexDirection={"column"}>
                  {week1 &&
                          <Typography color={"primary"}
                                      sx={{marginTop: '10%', textShadow: `1px 1px 2px ${palette.background.paper}`, textAlign: 'center', backdropFilter: uiState.backdropFilter}}
                                      variant="h5" component="p" gutterBottom>Don't worry! We won't start tracking goals
                            until week 1.</Typography>}
                  {goals.goals.map(x => (
                          <Grid key={x.id}>
                            <Typography color={uiState.color}>{x.goalText}</Typography>

                            {[...Array(x.frequency)].map((_, i) => (
                                    <ToggleButton disabled={!enabled}
                                                  color={"primary"}
                                                  onClick={(e) => handleToggle(e, x, i)}
                                                  selected={goalCompletionStatus[x.id]?.includes(i)}
                                                  key={i}
                                                  sx={{border: 0, borderRadius: 30, padding: .25, margin: .5}}
                                                  value={`${x.id}-${i}`}
                                    >
                                      {goalCompletionStatus[x.id]?.[i] === 1 ? <Circle color={uiState.buttonColor}/> :
                                              <CircleOutlined color={uiState.buttonColor}/>}
                                    </ToggleButton>
                            ))}

                          </Grid>
                  ))}
                </Grid>
        }
      </CardContent>
      {enabled && <CardActions sx={{alignItems: 'flex-end', justifyContent: "flex-end", flexGrow: 1}}>
        <Button onClick={() => setJailCardOpen(true)}>Show Available Jail Cards</Button>
        <Button disabled={!enabled} variant={"contained"} onClick={() => {
          refreshActivities()
          setHistoryDialogOpen(true)
        }}>View Recent Exercise</Button>
        {newGoalEnabled &&
                <Button disabled={!newGoalEnabled} variant={"contained"} onClick={onUpdateGoal}>Update Goals</Button>}


      </CardActions>}
    </Card>
    <RecordExercise onChange={handleActivityChange}
                    view={activityView}
                    open={activityTrackingDialogOpen}
                    onClose={() => {
                      setActivityTrackingDialogOpen(false)
                    }}
                    onSave={addActivity}></RecordExercise>
    <ExerciseHistory open={historyDialogOpen} onClose={() => setHistoryDialogOpen(false)}
                     view={activityHistoryView}></ExerciseHistory>

    <Dialog open={jailCardOpen} onClose={() => setJailCardOpen(false)}>
      <DebugPanel displayItem={jailCards}></DebugPanel>
      <DialogTitle>Available Get Out Of Jail Cards</DialogTitle>
      <DialogContent>
        <Typography component={"div"}>{"Available Get Out of Jail Free Cards:"}<Typography sx={{paddingLeft: 2}}
                                                                                           component={"span"}
                                                                                           variant={"h4"}>
          {jailCards.length}
        </Typography>
        </Typography>
        <List>
          {jailCards.map(x => {
            return (
                    <ListItem key={x.id}>
                      <img src={"https://images.ctfassets.net/ihnuhnmumsts/SvOPfL2qrjRB68cpu5e96/3ff57b3375721b425c6ce8eade8dfcbf/B34EE134-411A-4A51-98B2-D45705EAEA72.png?fm=webp&q=75"}
                           alt={"Jail"}></img>
                    </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setJailCardOpen(false)}>Dismiss</Button>
      </DialogActions>
    </Dialog>

    <ConfirmGoalCompletionDialog
            open={confirmGoalDialogOpen}
            onClose={() => setConfirmGoalDialogOpen(false)}
            goals={goals}
            selectedGoal={selectedGoal}
            setConfirmGoalDialogOpen={setConfirmGoalDialogOpen}
            setGoalCompletionStatus={setGoalCompletionStatus}
            setAlertOpen={setAlertOpen}
            trackActivity={trackActivity}>
    </ConfirmGoalCompletionDialog>

    <UpdateGoalsDialog
            validationErrors={validationErrors}
            showNewGoalDialog={showNewGoalDialog}
            view={view}
            addRawGoal={addRawGoal}
            onAddGoal={onAddGoal}
            onRemoveGoal={onRemoveGoal}
            onModalClose={onModalClose}
            handleChange={handleChange}
            rawGoals={rawGoals}
    >
    </UpdateGoalsDialog>

    <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} autoHideDuration={6000} open={alertOpen}
              onClose={() => setAlertOpen(false)}>
      {snackAlertAction}
    </Snackbar>
  </Fragment>
}

