import {Validator} from "fluentvalidation-ts";
import {GoalView}  from "../views/GoalView";


export class GoalFormValidator extends Validator<GoalView> {
  constructor() {
    super();

    this.ruleFor("activityId")
        .greaterThan(-1)
        .withMessage("Please select an activity.");

    this.ruleFor("frequency")
        .greaterThan(0)
        .withMessage("Frequency must be a positive number.");

    this.ruleFor("duration")
        .greaterThan(0)
        .withMessage("Duration must be a positive number.");

    this.ruleFor("startDate")
        .notNull()
        .withMessage("Start date is required.")
        .must(x => x.weekdayShort === 'Mon')
        .withMessage("Start date is required to be on Monday.");

    this.ruleFor("endDate")
        .notNull()
        .withMessage("End date is required.");

  }
}