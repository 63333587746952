/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, PropsWithChildren, useEffect, useMemo, useState} from "react";
import './Dashboard.scss'
import {useCookies} from "react-cookie";
import {useParams} from 'react-router';
import DashboardViewModel, {
  QuestionView,
  ResponseView,
  valueToActivityString,
  valueToString
} from '../../views/DashboardView'
import {DashboardViewCreator} from "../../view-creator/DashboardViewCreator";
import {Container, Grid2 as Grid, Skeleton, Stack, SxProps, Typography} from "@mui/material";
import {LeaderCard, MessageThread, TeamCard} from "../../components";
import {GoalTrackerCard} from "../../components/GoalTracking/GoalTrackerCard";
import {PotCard} from "./PotCard";
import CreateGoalAction from '../../actions/CreateGoalAction'
import SurveyDrawer from "../../components/SurveyDrawer/SurveyDrawer";
import {SaveSurveyResponsesAction} from "../../actions/SaveSurveyResponsesAction";
import {RawGoal} from "../../views/RawGoal";
import {DateTime} from "luxon";
import {GoalView} from "../../views/GoalView";
import logger from "../../utils/logger";
import {DebugPanel} from "../../components/DebugPanel";
import {ActivityHistoryView} from "../../views/ActivityHistoryView";
import {ActivityHistoryViewCreator} from "../../view-creator/ActivityHistoryViewCreator";
import {PaymentModal} from "../../components/modals/PaymentModal";
import Joyride, {ACTIONS, CallBackProps, LIFECYCLE, Step} from 'react-joyride';
import {palette} from "../../palette";
import {JoyrideContentViewCreator} from "../../view-creator/JoyrideContentViewCreator";
import {ScienceSomethingCard} from "../../components/ScienceSomethingCard";
import {ExerciseInDisguise} from "../../components/ExerciseInDisguise";
import {InfoDialog} from "../../components/InfoDialog";
import {InfoPanelViewCreator} from "../../view-creator/InfoPanelViewCreator";
import {ToDateTime} from "../../utils/DateUtils";
import {GroupDateMapper} from "../../utils/GroupDateMapper";


export const Dashboard: React.FC<PropsWithChildren> = () => {
  const [cookies] = useCookies([
    'profile'
  ])
  const initialGoalFormState: GoalView = {
    name: '',
    activityId: -1,
    duration: 0,
    durationTimeframe: -1,
    frequency: 0,
    timeframe: -1,
    startDate: DateTime.now()
                       .startOf('week', {useLocaleWeeks: true}),
    endDate: DateTime.now()
                     .startOf('week', {useLocaleWeeks: true})
                     .plus({'week': 4}),
    datesEnabled: false
  };
  const params = useParams()
  const [view, setView] = useState(DashboardViewModel.Empty)

  const [questions, setQuestions] = useState<QuestionView[]>([]);
  const [reload, setReload] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true)
  const [finishVisible, setFinishVisible] = useState<boolean>(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const [activityHistoryView, setActivityHistoryView] = useState<ActivityHistoryView>({items: []});
  const [formState, setFormState] = useState<GoalView[]>([
    initialGoalFormState,
    initialGoalFormState
  ]);
  const [steps, setSteps] = useState<Step[]>([]);

  const [infoDialogState, setInfoDialogState] = useState<{
    open: boolean, infoDialogContent: any | undefined
  }>({open: false, infoDialogContent: undefined});

  useEffect(() => {
    if (!reload) return;

    const resetState = () => {
      setActiveStep(0);
      setIsLoading(true);
      setReload(false);
    };

    const loadJoyrideContent = () => {
      return new JoyrideContentViewCreator().createView()
                                            .then(setSteps);
    };

    const loadDashboardView = async () => {
      const result = await new DashboardViewCreator().createViewWithArg({
        groupId: Number(params['groupId']), userId: String(cookies.profile.id)
      });
      allowNext(result.section.questions[activeStep]);
      allowFinish(activeStep);
      return result;
    };

    const loadInfoPanel = async (groupStartDate: DateTime) => {
      let info = await new InfoPanelViewCreator().createView(ToDateTime(groupStartDate));
      setInfoDialogState((prev) => ({
        ...prev, open: Boolean(info.info) && !info.dismissed, infoDialogContent: info.info
      }));
    };

    const updateFormState = (groupStartDate: DateTime) => {
      const start = typeof groupStartDate === 'string' ? DateTime.fromISO(groupStartDate) : groupStartDate;
      setFormState((original) => original.map(state => ({
        ...state, endDate: start.plus({day: 35}), startDate: start.plus({day: 8})
      })));
    };

    const loadActivityHistory = (cohortUserId: number) => {
      return new ActivityHistoryViewCreator().createView(cohortUserId)
                                             .then(setActivityHistoryView);
    };

    const initializeViews = async () => {
      resetState();
      await loadJoyrideContent();

      const result = await loadDashboardView();
      await loadInfoPanel(result.groupStartDate);
      updateFormState(result.groupStartDate);
      setView(result);

      if (result.section) {
        setQuestions(result.section.questions);
      }

      await loadActivityHistory(result.cohortUserId);
      setIsLoading(false);
    };

    initializeViews();
  }, [reload]);


  const [showGoalModal, setShowGoalModal] = useState(false);
  const handleUpdateGoal = () => {
    setShowGoalModal(!showGoalModal)
  };

  const handleClose = async (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => {
    logger.debug('goal dialog ->', reason)
    setShowGoalModal(false)
    if (reason === 'SAVE') {
      debugger
      const result = await new CreateGoalAction().Execute(goals)
      logger.debug(JSON.stringify(result, null, 2))
      setReload(true);
    }

  };
  const backgroundStyles: SxProps = {
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${view.logo})`, backgroundColor: palette.secondary.main, backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center', width: '100vw', borderTopLeftRadius: 15, borderTopRightRadius: 15, color: palette.primary.contrastText,
  }

  const [activeStep, setActiveStep] = useState<number>(0)
  const handleNext = () => {
    allowFinish(activeStep + 1)
    allowNext(questions[activeStep + 1])
    setActiveStep(activeStep + 1)

  }
  const handlePrev = () => {
    allowFinish(activeStep - 1)
    allowNext(questions[activeStep - 1])
    setActiveStep(activeStep - 1)
  }

  function allowFinish(currentStep: number) {
    const questionsAnswered = questions
            .filter(q => q.responses.some(r => Boolean(r.textResponse))).length;
    const showFinish = questionsAnswered === questions.length && currentStep === questions.length - 1;
    setFinishVisible(showFinish)
  }

  function allowNext(currentQuestion: QuestionView) {
    if (currentQuestion) {
      const nextAllowed = currentQuestion.responses.some(x => Boolean(x.textResponse))
      setNextDisabled(!nextAllowed)
    }
  }

  const handleFreeTextResponse = (e: any, option: ResponseView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(x => x.id === option.id)
    currentQuestion.responses[optIdx].textResponse = e.target.value;
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions])
  };

  function setQuestionResponse(currentQuestion: QuestionView, optIdx: number, e: any) {
    currentQuestion.responses[optIdx].selected = Boolean(e.target.checked)
    if (e.target.checked) {
      currentQuestion.responses[optIdx].textResponse = String(e.target.value);
    }
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions])
  }

  const handleMultiSelectResponse = (e: any, option: ResponseView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(x => x.id === option.id)
    currentQuestion.responses[optIdx].textResponse = ''
    setQuestionResponse(currentQuestion, optIdx, e);
  };

  const handleMutuallyExclusiveResponse = (e: any, question: QuestionView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(x => x.id === Number(e.target.value))
    currentQuestion.responses[optIdx].textResponse = ''
    currentQuestion.responses.forEach(x => {
      x.selected = false
      x.textResponse = ''
    })
    setQuestionResponse(currentQuestion, optIdx, e);
  };
  const handleAccountabilityPartnerResponse = (e: any, opt: ResponseView) => {
    const currentQuestion = questions[activeStep];
    const optIdx = currentQuestion.responses.findIndex(x => x.id === opt.id)
    currentQuestion.responses[optIdx].textResponse = ''
    currentQuestion.responses.forEach(x => {
      x.selected = false
      x.textResponse = ''
    })
    currentQuestion.responses[optIdx].selected = true
    currentQuestion.responses[optIdx].textResponse = String(e.target.value);
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions])
  };

  const handleSaveResponses = async (e: any, reason: 'GOAL' | 'SURVEY_RESPONSE') => {
    view.section!.questions = [...questions];
    if (reason === 'GOAL') {

      const rawGoals = formState.map((state) => {
        return {
          name: buildGoalText(state.activityId, state.frequency, state.timeframe, state.duration, state.durationTimeframe),
          cohortUserId: view.cohortUserId,
          activityId: state.activityId,
          duration: state.duration,
          durationTimeframeId: state.durationTimeframe,
          frequency: state.frequency,
          timeframeId: state.timeframe,
          endDate: state.endDate,
          startDate: state.startDate
        } as RawGoal
      })
      await new CreateGoalAction().Execute(rawGoals)
    }

    await new SaveSurveyResponsesAction().Execute(view.section, view.groupId)

    setReload(true)

  };

  function buildGoalText(activity: number, frequency: number, timeframe: number, duration: number, durationTimeframe: number) {
    return `I commit to ${valueToActivityString(activity)} ${frequency} ${valueToString(timeframe)} for ${duration} ${valueToString(durationTimeframe)}`;
  }

  function onGoalResponse(e: any, option: ResponseView | undefined) {

    if (!option) {
      throw Error('Option cannot be null')
    }
    const {name, value} = e.target;
    const currentQuestion = questions[activeStep];
    const formStateIdx = currentQuestion.id === 130 ? 0 : 1;

    const state = {...formState[formStateIdx]};
    const newFormState = {
      ...state, [name]: value
    }
    formState[formStateIdx] = {...newFormState}
    setFormState([...formState])
    const optIdx = currentQuestion.responses.findIndex(x => x.id === option.id)
    currentQuestion.responses[optIdx].textResponse = buildGoalText(newFormState.activityId, newFormState.frequency, newFormState.timeframe, newFormState.duration, newFormState.durationTimeframe)
    allowNext(currentQuestion);
    allowFinish(activeStep);
    setQuestions([...questions])
  }

  type CardName = 'Goal' | 'Pot' | 'Exercise' | 'Science' | 'Leader' | 'NewGoal'
  type CardsEnabled = {
    [card in CardName]: { enabled: boolean; message: string; };
  };


  const week1: boolean = useMemo(() => {

    const groupMapper = new GroupDateMapper(ToDateTime(view.groupStartDate)
            .toJSDate())
    const nowString = DateTime.now()
                              .toISO()
    return groupMapper.day(nowString) < 8;

  }, [reload])

  const cardsEnabled: CardsEnabled = useMemo(() => {
    //TODO: each card should have its own enabled state based on date
    // Goal: day 2
    // Pot: day 4
    // Exercise: Day 8
    // Science: Day 12
    // Leader: Day 15

    const startDate = (typeof view.groupStartDate === 'string') ? DateTime.fromISO(view.groupStartDate) : view.groupStartDate
    return {
      'Goal': {enabled: !(DateTime.now() < startDate.plus({day: 1})), message: 'Get fired up! This part of the program starts Tuesday.'},
      'Pot': {enabled: !(DateTime.now() < startDate.plus({day: 3})), message: 'Get excited! This part of the program starts Thursday.'},
      'Exercise': {enabled: !(DateTime.now() < startDate.plus({day: 7})), message: 'Get pumped! This part of the program starts Monday of Week 1.'},
      'Science': {enabled: !(DateTime.now() < startDate.plus({day: 11})), message: 'Get stoked! This part of the program starts Friday of Week 1.'},
      'Leader': {enabled: !(DateTime.now() < startDate.plus({day: 14})), message: 'Get ready! This part of the program starts Monday of Week 2.'},
      'NewGoal': {enabled: !week1 && view.goals && view.goals.length === 0, message: ''}
    }


  }, [
    reload,
    week1,
    view.goals.length,
    view.groupStartDate])


  const [run, setRun] = useState<boolean>(true);
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {action, lifecycle} = data;
    if (action === ACTIONS.SKIP || (action === ACTIONS.NEXT && lifecycle === LIFECYCLE.COMPLETE)) {
      localStorage.setItem('dashboard:joyrideskipped', 'true')
      return;
    }
    if (action === ACTIONS.START) {
      const joyrideSkipped = localStorage.getItem('dashboard:joyrideskipped')
      if (Boolean(joyrideSkipped)) {
        setRun(false)
      }
    }
  }
  return (<Fragment>

    <Joyride continuous={true} steps={steps} showProgress={true} run={run}
             styles={{options: {primaryColor: palette.primary.main}}}
             showSkipButton={true} callback={handleJoyrideCallback}
    />


    <Container sx={backgroundStyles}>
      <Stack direction={"column"} gap={"16px"}>
        <Container sx={{padding: 2}}>
          {isLoading && <Typography variant={"h3"}>
            <Skeleton width="80%"/>
          </Typography>}
          {!isLoading && <Typography variant={"h4"}>
            {view.groupName}
          </Typography>}
        </Container>
        <Grid container spacing={2}>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 6, xl: 6}}>
            <div className="messageCardJoyride">
              <MessageThread conversationId={view.groupChatId} groupId={view.groupId}
                             groupName={view.groupName}/>
            </div>
          </Grid>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 6, xl: 6}}>
            <div className="teamCardJoyride">
              <TeamCard isLoading={isLoading} view={view}/>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 6, xl: 6}}>
            <div className="goalsCardJoyride">
              <GoalTrackerCard week1={week1} enabled={cardsEnabled.Goal.enabled}
                               onModalClose={handleClose}
                               showNewGoalDialog={showGoalModal}
                               newGoalEnabled={cardsEnabled.NewGoal.enabled}
                               disabledMessage={cardsEnabled.Goal.message}
                               isLoading={isLoading}
                               activityHistoryView={activityHistoryView}
                               cohortUserId={view.cohortUserId}
                               goals={{goals: view.goals, progress: view.progress}}
                               jailCards={view.availableJailCards}
                               onUpdateGoal={handleUpdateGoal}
                               refreshActivities={() => setReload(true)}/>
            </div>
          </Grid>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 6, xl: 6}}>
            <div className={'potCardJoyride'}>
              <PotCard enabled={cardsEnabled.Pot.enabled}
                       disabledMessage={cardsEnabled.Pot.message}
                       isLoading={isLoading} cohortUserId={view.cohortUserId}
                       label={"Pledge Pot"}
                       value={Intl.NumberFormat('en-US', {currency: 'USD', style: "currency"})
                                  .format(view.potAmount)}/>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}}>
            <div className="exerciseInDisguiseJoyride">
              <ExerciseInDisguise enabled={cardsEnabled.Exercise.enabled}
                                  groupStart={view.groupStartDate}
                                  disabledMessage={cardsEnabled.Exercise.message}
                                  triggerReload={(reload) => setReload(reload)}></ExerciseInDisguise>
            </div>
          </Grid>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}}>
            <div className="scienceSomethingJoyride">
              <ScienceSomethingCard enabled={cardsEnabled.Science.enabled}
                                    startDate={view.groupStartDate}
                                    disabledMessage={cardsEnabled.Science.message}
                                    triggerReload={(reload) => setReload(reload)}></ScienceSomethingCard>
            </div>
          </Grid>
          <Grid size={{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}}>
            <div className="leaderCardJoyride">
              <LeaderCard enabled={cardsEnabled.Leader.enabled}
                          disabledMessage={cardsEnabled.Leader.message}
                          leaders={view.leaders}></LeaderCard>
            </div>
          </Grid>
        </Grid>
        <Container component={"section"}>
          <Stack direction={"row"} justifyContent={"space-between"} gap={"16px"}></Stack>
        </Container>
      </Stack>
      <DebugPanel displayItem={view}/>
    </Container>
    {view.needPayment &&
            <PaymentModal cohortUserId={view.cohortUserId} groupId={view.groupId} open={view.needPayment}
                          amount={view.pledgeAmount}/>}
    {view.section && view.section.questions.length > 0 && <SurveyDrawer
            surveyTitle={view.section.title}
            participants={view.team.participants}
            saveResponses={handleSaveResponses}
            nextDisabled={nextDisabled}
            finishVisible={finishVisible}
            questions={questions}
            activeStep={activeStep}
            handleNext={handleNext}
            handlePrev={handlePrev}
            goalFormState={formState}
            handleGoalResponse={onGoalResponse}
            handleFreeTextResponse={handleFreeTextResponse}
            handleMultiSelectResponse={handleMultiSelectResponse}
            handleMutuallyExclusiveResponse={handleMutuallyExclusiveResponse}
            handleAccountabilityPartnerResponse={handleAccountabilityPartnerResponse}
    />}

    <InfoDialog message={infoDialogState.infoDialogContent?.content ?? ''}
                title={infoDialogState.infoDialogContent?.title ?? ''} onDismiss={() => {
      if (infoDialogState.infoDialogContent.title) {
        localStorage.setItem(`dashboard:info:${infoDialogState.infoDialogContent.title}`, 'true')
      }
      setInfoDialogState({...infoDialogState, open: false});
    }} onClose={() => {
      setInfoDialogState({...infoDialogState, open: false});
    }} onReadLater={() => setInfoDialogState({...infoDialogState, open: false})}
                open={infoDialogState.open}></InfoDialog>


  </Fragment>)
}