import {GoalFormValidator} from "../validators/GoalFormValidator";
import {GoalStore}         from "../api/GoalStore";
import {GoalView}          from "../views/GoalView";
import {RawGoal}           from "../views/RawGoal";

export const ValidateGoalFormAction = {
  Execute: async function (
    cohortUserId: number,
    rawFormState: GoalView,
    options: { validateField?: string } = {}
  ): Promise<{ errors: Record<string, string | null>; success: boolean }> {
    const validator = new GoalFormValidator();
    const errors: Record<string, string | null> = {};

    // Real-time validation for a single field
    if (options.validateField) {
      const fieldErrors = validator.validate(rawFormState);

      Object.entries(fieldErrors)
            .forEach(([key, value]) => {
              if (key === options.validateField) {
                errors[options.validateField] = String(value)
              }
            })

      return {errors, success: Object.keys(errors).length === 0};
    }

    // Full form validation
    const validationResults = validator.validate(rawFormState);

    Object.entries(validationResults)
          .forEach(([key, value]) => {
            errors[key] = String(value)
          })


    if (Object.keys(errors).length > 0) {
      return {errors, success: false};
    }

    const rawGoal: RawGoal = {
      activityId: rawFormState.activityId,
      startDate: rawFormState.startDate,
      duration: rawFormState.duration,
      endDate: rawFormState.endDate,
      cohortUserId: cohortUserId,
      frequency: rawFormState.frequency,
      name: rawFormState.name,
      durationTimeframeId: rawFormState.durationTimeframe,
      timeframeId: rawFormState.timeframe,
    }
    // Persist data if valid
    try {
      await new GoalStore().Write([rawGoal]);
      return {errors: {}, success: true};
    } catch {
      return {errors: {general: "Failed to save the goal."}, success: false};
    }
  },
};